import React, { useState, useContext, useEffect } from "react";
import Link from "next/link";
import NewFeedback from "../modals/NewFeedback";
import { GlobalContext } from "../context/GlobalContext";
import useUserLogStatus from "../customHooks/useUserLogStatus";
import FacebookIcon from "./Icons/FacebookIcon";
import InstagramIcon from "./Icons/InstagramIcon";
import TeammatemeIcon from "./Icons/TeammatemeIcon";
import TwitterIcon from "./Icons/TwitterIcon";
import YoutubeIcon from "./Icons/YoutubeIcon";
import TikTokIcon from "./Icons/TiktokIcon";
import { Button } from "@mui/material";
import ReportModal from "components/modals/Report/ReportModal";

let deferredPrompt;

const Footer = () => {
  const appState = useContext(GlobalContext);
  const { currentUser } = appState;

  const [showFeedbackButton, displayFeedbackButton] = useState(false);
  const [showFeedbackModal, displayFeedbackModal] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [installFlag, setInstallFlag] = useState(false);

  const toggleFeedbackModal = () => {
    displayFeedbackModal(!showFeedbackModal);
  };

  const isLoggedIn = useUserLogStatus();

  useEffect(() => {
    if (currentUser === undefined) {
      displayFeedbackButton(false);
    } else {
      displayFeedbackButton(true);
    }
  }, [currentUser, showFeedbackButton]);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt = e;
      setInstallFlag(true);
    });
  }, []);

  const handleInstallTeammateMe = () => {
    if (deferredPrompt !== undefined) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        deferredPrompt = null;
        setInstallFlag(false);
      });
    }
  };

  return (
    <>
      {openReports && <ReportModal setOpenReports={setOpenReports} />}
      <div className={isLoggedIn ? "footer_wrapper-white" : "footer_wrapper"}>
        <footer className="container--footer">
          <div
            className="container--footer-left"
            role="navigation"
            aria-label="Company information"
          >
            <h5 className="header header--footer_section_title">Company</h5>
            <Link href="/about" className="link--footer link--pagenav">
              About us
            </Link>
            <Link href="/careers" className="link--footer link--pagenav">
              Careers
            </Link>
            <Link
              href="/termsandconditions"
              className="link--footer link--pagenav"
            >
              Terms of Use
            </Link>
            <Link href="/privacypolicy" className="link--footer link--pagenav">
              Privacy Policy
            </Link>
            <Link href="/cookies" className="link--footer link--pagenav">
              Cookie Policy
            </Link>
          </div>
          <div className="container--footer-center">
            <h5 className="header header--footer_section_title">Resources</h5>
            <Link href="/blogroll" className="link--footer link--pagenav">
              Blog
            </Link>
            {showFeedbackModal && (
              <NewFeedback toggleFeedbackModal={toggleFeedbackModal} />
            )}
            {showFeedbackButton && (
              <button
                onClick={toggleFeedbackModal}
                className="button button--footer link--pagenav"
                role="button"
                aria-label="Give feedback"
              >
                Feedback
              </button>
            )}
            <Link href="/help" className="link--footer link--pagenav-border">
              Help
            </Link>
            <Link
              href="mailto:support@teammateme.org"
              className="link--footer link--pagenav_support_email"
            >
              support@teammateme.org
            </Link>
            <a
              href="#"
              onClick={() => setOpenReports(true)}
              className="link--footer link--pagenav-border"
            >
              Report issue
            </a>
          </div>
          <div className="container--footer-right">
            <h5 className="header header--footer_copyright_title">
              Stay Connected
            </h5>
            <div className="container--social_media_icons">
              <a
                href="https://www.facebook.com/TeammateMe"
                className="link link--footer_social"
                rel="noreferrer noopener"
                target="_blank"
                aria-label="Facebook page of TeammateMe"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://www.instagram.com/teammatemeapp/"
                className="link link--footer_social"
                rel="noreferrer noopener"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.linkedin.com/company/TeammateMe"
                className="link link--footer_social"
                target="_blank"
                rel="noreferrer noopener"
              >
                <TeammatemeIcon />
              </a>
              <a
                href="https://twitter.com/teammateme"
                className="link link--footer_social"
                target="_blank"
                rel="noreferrer noopener"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://www.youtube.com/@teammateme"
                className="link link--footer_social"
                target="_blank"
                rel="noreferrer noopener"
              >
                <YoutubeIcon />
              </a>
              <a
                href="https://www.tiktok.com/@teammateme.com"
                className="link link--footer_social"
                target="_blank"
                rel="noreferrer noopener"
              >
                <TikTokIcon />
              </a>
            </div>
            <div>
              <Button
                className={`install-teammateme ${installFlag ? "button--dashboard-primary" : "button--dashboard-primary-disabled"}`}
                variant="contained"
                onClick={() => handleInstallTeammateMe()}
                aria-label="Install TeammateMe app"
                disabled={!installFlag}
              >
                <span>
                  <strong>{installFlag ? "Install TeammateMe" : "TeammateMe Installed"}</strong>
                </span>
              </Button>
            </div>
            <p className="footer--copyright">
              &#169; 2025 Teammate<span className="makeGreen">Me</span>,
              All Rights Reserved.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
